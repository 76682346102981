import React from 'react'
import logo from '../images/harris-logo.png'
import iceCream from '../images/ice-cream.svg'

const Header = () => {
    return(
        <header>
            <div className="d-flex align-items-center justify-content-center">
                <img src={logo} className="logo" alt="" />
            </div>
            <div className="container">
                <div className="row ">
                    <div className="col-md-4 order-1">
                        <h2 className="heading mt-3 c-main">
                        The Pure Milk Ice Cream
                        </h2>
                        <p className="fs-5 mt-2 c-main mb-5">
                        Harris Ice creams are made from pure milk and does not use any vegetable oils! Enjoy the wide range of flavors and stay healthy!
                        </p>
                        <a href="tel:+919888858027" className="px-5  py-3 fs-5 top-cta">Call Us!</a>
                    </div>
                    <div className="col-md-4 order-3 order-md-2">
                        <img src={iceCream} className="mt-5 mx-auto d-block ice-img" alt="" />
                    </div>
                    <div className="col-md-4  d-flex order-2 order-md-3  justify-content-center align-items-start p-5 px-md-5 px-2">
                        <div className="contact p-4 rounded-5">
                            <h3 className="text-white text-center fw-bold mb-3">
                            Contact Us
                            </h3>
                            <div className="row">
                                <div className="col-2"><i class="fas fa-map-marker-alt m-2"></i></div>
                                <div className="col-10">
                                    <p className="text-white">
                                        Village-Datewas Budhlada, Punjab region 151502
                                    </p>
                                </div>
                                <div className="col-2"><i class="fas fa-phone"></i></div>
                                <div className="col-10 d-flex align-items-center">
                                    <p className="d-inline m-0">
                                        <a href="tel:+919888858027" className="text-white">
                                    +91 98888 58027</a>
                                    </p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
