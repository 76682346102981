import React from 'react'

const Footer = () => {
    return(
        <>
            <p className="text-center p-5">Copyright 2021 - Harris</p>
        </>
    )
}

export default Footer