import React from 'react'

const Product = (props) => {
    return(
        <>
        <div className="col-md-3 col-sm-4 col-6 m-0 p-0 rounded-5">
            <div className="product m-3 mb-4 m-0 p-2 p-md-3 rounded-5">
                <img src={props.image} className="img-fluid w-md-50"  alt="" />
                <p className="text-center c-main fw-bold fs-4">
                    {props.name}
                </p>
            </div>
        
        </div>
        </>
    )
}
export default Product
