import './App.css';
import Footer from './Components/Footer';
import Header from "./Components/Header"
import Product from './Components/Product';
import Data from "./Data/data.json"

function App() {

  return (
    <>
      <Header />
      <h2 className="heading c-main text-center mt-5 p-5 pb-3">
        Our Products
      </h2>
      <div className="container">
        <p className="c-main fs-md-5 fs-6">
          You can indulge yourself with our creamy, smooth, rich and fresh taste of pure milk ice-cream anytime. We offer various flavors like vanilla, chocolate, strawberry etc., which are loved by all age groups. So go ahead and buy your favorite flavor today!
        </p>
      </div>
      <div className="container">
      <div className="row pt-3">

      
      
      {
        Data.map(Data =>
           <Product name={Data.name} image={Data.image}/>
      )
      
      }
      </div>
      </div>
      <Footer/>
    </>
  );
}

export default App;
